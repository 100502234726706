import { Alert } from "antd";
import { useEffect, useState } from "react";
import createWish from "./Helpers/CreateWish";
import AdminMenu from "./Layouts/AdminMenu";
//createWish(title,url,person,category) all parameters as strings

const contentful = require('contentful')

const fetchClient = contentful.createClient({
    space: "bbnewkew8oy0",
    accessToken: "1g0Vz-lV9fMv9lh_Ee9KYQRDIHoVm9wyZUzM0Bt6ZDA"
})

export default function Addwish() {
    const [people, setPeople] = useState(null)
    const [categories, setCategories] = useState(null)
    const [saved, setSaved] = useState(null)

    useEffect(() => {
        fetchClient.getEntries({content_type: "person"}).then(function(entries) { setPeople(entries) })
        fetchClient.getEntries({content_type: "wishCategory"}).then(function(entries) { setCategories(entries) })
    }, [])

    console.log(categories)

    const clearWish = () => {
        setSaved("ok")
        setTimeout(() => {
            setSaved(null)
            document.querySelector("#title").value = "";
            document.querySelector("#url").value = "";
        }, 3000)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if(event.target[0] !== "") {
            if(event.target[5].value === "96") {
                createWish(event.target[0].value, event.target[1].value, event.target[2].value, event.target[4].value, event.target[3].value).then((cw) => cw.status === "success" ? clearWish() : setSaved("error"))
            } else {
                setSaved("pinerror")
            }
        } else {
            setSaved("empty")
        }
        
    }

    return(
        <section className="container">
            <AdminMenu />
            <h1>Legg til ønske</h1>
            {
                saved === "ok" ? <Alert message="Ønske er lagt til" type="success" /> : 
                saved === "pinerror" ? <Alert message="Feil pin" type="error" /> : 
                saved === "error" ? <Alert message="Ønske kunne ikke legges til nå" type="warning" /> : 
                saved === "emtpy" ? <Alert message="Du må legge inn tittel/tekst" type="warning" /> :
            null
            }
            <form id="addform" onSubmit={handleSubmit} method="post">
                <div className="formfield"><label htmlFor="title">Tittel/tekst*:</label> <input type="text" name="title" id="title" /></div>
                <div className="formfield"><label htmlFor="url">Link:</label> <input type="text" name="url" id="url" /></div>
                <div className="formfield"><label htmlFor="desc">Beskrivelse:</label> <textarea name="desc" id="desc"></textarea></div>
                <div className="formfield"><label htmlFor="category">Kategori:</label> 
                    <select name="category" id="category">
                        {categories?.items?.map((p,i) => <option key={i} value={p.sys.id}>{p.fields.title}</option>)}
                    </select>
                </div>
                <div className="formfield"><label htmlFor="person">Person:</label> <select name="person" id="person">
                    {people?.items?.map((p,i) => <option key={i} value={p.sys.id}>{p.fields.name}</option>)}
                </select></div>
                <div className="formfield"><label htmlFor="sec">Pin code:</label><input type="number" name="sec" id="sec" /></div>
                <button id="saveWish" className="button" type="submit">Lagre ønske</button>
            </form>
        </section>
    )
}