import { PlusIcon } from "@heroicons/react/24/outline";
import { NavLink } from "react-router-dom";

export default function AdminMenu() {
    return(
        <nav id="admin">
            <ul>
                <li><NavLink to="/add"><PlusIcon /> Ønske</NavLink></li>
                <li><NavLink to="/addevent"><PlusIcon /> Kalenderoppføring</NavLink></li>
            </ul> 
        </nav>
    )
}