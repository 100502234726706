import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import DatePrint from "./Helpers/DatePrint";

export default function EventCard({e, i}) {
    return (
        <article className="eventCard">
            <img src={e.fields.participant.fields.image.fields.file.url} alt={e.fields.name} />
            <h3><span>{e.fields.category}</span>{e.fields.title}</h3>
            <p>{e.fields.location}</p>
            <p><CalendarDaysIcon /> <DatePrint date={e.fields.eventtime} /></p>
        </article>
    )
}