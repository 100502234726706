import {useState, useEffect} from "react";
import { EnvelopeIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'
import { SocialIcon } from 'react-social-icons';
import {Link} from "react-router-dom";
import Loader from "./Helpers/Loader";
import { client } from "../lib/client";
import PrintBirthday from "./Helpers/PrintBirthday";
import EventCard from "./EventCard";


export default function People() {
    const currentDate = new Date()
    const [people, setPeople] = useState(null)
    const [eventItems, setEventItems] = useState(null)

    async function fetchPeople() {
        await client.getEntries({
            content_type: 'person'
        }).then((response) => {setPeople(response.items)}).catch(console.error)
    }

    async function fetchEvents() {
        await client.getEntries({
            content_type: 'event',
            order: 'fields.eventtime'
        }).then((response) => {setEventItems(response.items)})
    }

    useEffect(() => {
        fetchPeople()
        fetchEvents()
    }, [])

    console.log(people)

    if(!people) {
        return <Loader print="Henter familiemedlemmer..." />;
    }

    return(
        <section id="frontpage">
            <section id="events">
                <h2>Hva skjer?</h2>
                {eventItems?.filter((f) => f.fields.eventtime >= currentDate.toISOString())
                .slice(0,5).map((e, i) => (<EventCard e={e} key={i} />))}
                <a className="button" href="/kalender">Se hele kalenderen</a>
            </section>
        <section id="people">
            {people?.map((p, i) => (
            <article className="person" key={i}>
            <h2>{p.fields.name}</h2>
            <figure className="profileimage">
                <img src={p?.fields?.image?.fields?.file?.url ? p?.fields?.image?.fields?.file?.url  : `https://dummyimage.com/500x500&text=${p.fields.name}`} alt={`Profilbilde av ${p.fields.name}`} />
            </figure>

            <aside className="workinfo">
                <p><b>{p.fields.worktitle}</b><br /><a href={p.fields.worklink} target="_blank" rel="noreferrer">{p.fields.workplace}</a></p>
            </aside>
            
            <ul id="personal">
                {p.fields.birthday ? <li><CalendarDaysIcon /><PrintBirthday birthdate={p.fields.birthday} /></li> : null}   
                {p.fields.email ? <li><EnvelopeIcon />{p.fields.email}</li> : null}
            </ul>

            <ul id="social" className="flex">
                {p.fields.facebook ? <li><SocialIcon url={p.fields.facebook} /></li> : null}  
                {p.fields.linkedInProfile ? <li><SocialIcon url={p.fields.linkedInProfile} /></li> : null}
                {p.fields.twitter ? <li><SocialIcon url={p.fields.twitter} /></li> : null}
            </ul>

                        <Link className="button" to={`/onskelister/${p.fields.name}`}>Se ønskeliste</Link>
                    </article>)
                )}
            </section></section>
    )
}