import People from './components/People';
import './sass/main.scss';
import {Routes, Route} from "react-router-dom";
import Wishlist from './components/Wishlist';
import Layout from './components/Layouts/Layout';
import Addwish from './components/Addwish';
import WishlistPerson from './components/WishlistPerson';
import Calendar from './components/Calendar';
import AddEvent from './components/AddEvent';
//https://graphql.contentful.com/content/v1/spaces/bbnewkew8oy0/explore?access_token=1g0Vz-lV9fMv9lh_Ee9KYQRDIHoVm9wyZUzM0Bt6ZDA



function App() {
  

  return (
    <Routes>
      <Route element={<Layout />}>
        <Route index element={<People />} />
        <Route path="/onskelister" element={<Wishlist />} />
        <Route path="/onskelister/:name" element={<WishlistPerson />} />
        <Route path="/kalender" element={<Calendar />} />
        <Route path="/add" element={<Addwish />} />
        <Route path="/addevent" element={<AddEvent />} />
      </Route>
    </Routes>
  );
}

export default App;
