const contentful = require('contentful-management')

const createEvent = async (title, location, category, eventtime, participant) => {
    console.log(eventtime)
    // Create the CMA client
    const client = contentful.createClient({
        accessToken: "CFPAT-F-ORCV7M72oMTQwtTR44W9QXiaCYcYY_wzAthOIcbmU"
    })
    
    //const eventtimeISO = eventtime.$d.toISOString()
    

    // Get your Contentful space and environment
    const space = await client.getSpace("bbnewkew8oy0")
    const env = await space.getEnvironment('master')

    // Our entry to be created
    const entryFields = {
        title: {
            "en-US": title
        },
        location: {
            'en-US': location
        },
        category: {
            'en-US': category
        },
        eventtime: {
            'en-US': eventtime
        },
        participant: {
            "en-US":{
                "sys":{
                    "type": "Link",
                    "linkType": "Entry",
                    "id": participant
                }
            }
        }
    }

    const contentType = 'event' // Contentful model type

    // Execute entry creation
    const entry = await env.createEntry(contentType, {
        fields: entryFields
    })

    // If we get a new entry ID, then success, otherwise default to null
    const newEntryId = entry.sys.id ?? null;

    if (newEntryId) {
        // Publish our entry, if success
        const pub = await entry.publish();
        console.log(pub)
        return ({
            'status': 'success',
            'entryId': newEntryId
        })
    } else {
        console.log(entry)

        return ({
            'status': 'error',
            'message': 'Failed to create post'
        })
    }
}

export default createEvent