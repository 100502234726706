import { Alert } from "antd";
import { useEffect, useState } from "react";
import { DatePicker } from 'antd';
import createEvent from "./Helpers/CreateEvent";
import AdminMenu from "./Layouts/AdminMenu";

const contentful = require('contentful')

const fetchClient = contentful.createClient({
    space: "bbnewkew8oy0",
    accessToken: "1g0Vz-lV9fMv9lh_Ee9KYQRDIHoVm9wyZUzM0Bt6ZDA"
})



export default function AddEvent() {
    const [people, setPeople] = useState(null)
    const [saved, setSaved] = useState(null)

    const onChange = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
    };
    const onOk = (value) => {
    console.log('onOk: ', value);
    };

    useEffect(() => {
        fetchClient.getEntries({content_type: "person"}).then(function(entries) { setPeople(entries) })
    }, [])

    const clearEvent = () => {
        setSaved("ok")
        setTimeout(() => {
            setSaved(null)
            document.querySelector("input").value = "";
        }, 3000)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        if(event.target[0] !== "") {
            if(event.target[5].value === "96") {
                //createWish(event.target[0].value, event.target[1].value, event.target[2].value, event.target[4].value, event.target[3].value).then((cw) => cw.status === "success" ? clearEvent() : setSaved("error"))
                createEvent(event.target[0].value,event.target[2].value,event.target[1].value,event.target[3].value,event.target[4].value).then((cw) => cw.status === "success" ? clearEvent() : setSaved("error"))
            } else {
                setSaved("pinerror")
            }
        } else {
            setSaved("empty")
        }
        
    }

    return(
        <section className="container">
            <AdminMenu />
            <h1>Legg til kalenderoppføring</h1>
            {
                saved === "ok" ? <Alert message="Kalenderoppføring er lagt til" type="success" /> : 
                saved === "pinerror" ? <Alert message="Feil pin" type="error" /> : 
                saved === "error" ? <Alert message="Kalenderoppføring kunne ikke legges til nå" type="warning" /> : 
                saved === "emtpy" ? <Alert message="Du må legge inn tittel/tekst" type="warning" /> :
            null
            }
            <form id="addform" onSubmit={handleSubmit} method="post">
                <div className="formfield"><label htmlFor="title">Tittel/tekst*:</label> <input type="text" name="title" id="title" /></div>
                <div className="formfield"><label htmlFor="cateogry">Kategori:</label> <input type="text" name="category" id="category" /><small>Kort beskrivelse, f.eks "Fotballkamp"</small></div>
                <div className="formfield"><label htmlFor="location">Sted:</label> <input type="text" name="location" id="location" /><small>Kan være en URL dersom det er et online event</small></div>
                <div className="formfield"><label htmlFor="eventtime">Tidspunkt:</label>
                    <DatePicker showTime format={"YYYY-MM-DDTHH:mm:ssZ"} onChange={onChange} onOk={onOk} />
                </div>
                <div className="formfield"><label htmlFor="participant">Person:</label> <select name="participant" id="participant">
                    {people?.items?.map((p,i) => <option key={i} value={p.sys.id}>{p.fields.name}</option>)}
                </select></div>
                <div className="formfield"><label htmlFor="sec">Pin code:</label><input type="number" name="sec" id="sec" /></div>
                <button id="saveEvent" className="button" type="submit">Lagre kalenderoppføring</button>
            </form>
            
        </section>
    )
}