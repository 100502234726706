export default function PrintBirthday({birthdate}) {
    const birthday = new Date(birthdate);
    const now = new Date();
    const diff = new Date(now - birthday);
    var epoch = new Date('1970-01-01 00:00:00-0600');
    const age = diff.getYear() - epoch.getYear();

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    return `${birthday.toLocaleDateString('no-NB', options)} (${age} år)`;
}