import { Link, Outlet } from "react-router-dom";

export default function Layout() {
    return(
        <>
        <header>
            <h1><span>Den lille familien</span> Akerbæk</h1>
            <nav><Link to={"/"}>Hjem</Link><Link to={"/onskelister"}>Ønskelister</Link></nav>
        </header>
        <main>
            <Outlet />
        </main>
        <footer>
            <p>Familien Akerbæk. Fangebakken 24. 1788 Halden.</p>
            <p>Made with Contentful, React, Ant Design, HeroIcons and SocialIcons.</p>
            <p>Attributions: <a href="https://www.flaticon.com/free-icons/clothing" title="clothing icons">Clothing icons created by Freepik - Flaticon</a>, <a href="https://www.flaticon.com/free-icons/toys" title="toys icons">Toys icons created by Freepik - Flaticon</a>, <a href="https://www.flaticon.com/free-icons/food" title="food icons">Food icons created by Freepik - Flaticon</a>, <a href="https://www.flaticon.com/free-icons/excercise" title="excercise icons">Excercise icons created by GOWI - Flaticon</a>, <a href="https://www.flaticon.com/free-icons/gift" title="gift icons">Gift icons created by Freepik - Flaticon</a>, <a href="https://www.flaticon.com/free-icons/money" title="money icons">Money icons created by Dimitry Miroliubov - Flaticon</a></p>
        </footer>
        </>
    )
}