const contentful = require('contentful-management')

const createWish = async (title, url, desc, person, category) => {
    // Create the CMA client
    const client = contentful.createClient({
        accessToken: "CFPAT-F-ORCV7M72oMTQwtTR44W9QXiaCYcYY_wzAthOIcbmU"
    })

    // Get your Contentful space and environment
    const space = await client.getSpace("bbnewkew8oy0")
    const env = await space.getEnvironment('master')

    // Our entry to be created
    const entryFields = {
        title: {
            "en-US": title
        },
        url: {
            'en-US': url
        },
        desc: {
            'en-US': desc
        },
        person: {
            "en-US":{
                "sys":{
                    "type": "Link",
                    "linkType": "Entry",
                    "id": person
                }
            }
        },
        category: {
            "en-US":{
                "sys":{
                    "type":"Link",
                    "linkType":"Entry",
                    "id": category
                }
            }
        }
    }

    const contentType = 'wish' // Contentful model type

    // Execute entry creation
    const entry = await env.createEntry(contentType, {
        fields: entryFields
    })

    // If we get a new entry ID, then success, otherwise default to null
    const newEntryId = entry.sys.id ?? null;

    if (newEntryId) {
        // Publish our entry, if success
        const pub = await entry.publish();
        console.log(pub)
        return ({
            'status': 'success',
            'entryId': newEntryId
        })
    } else {
        console.log(entry)

        return ({
            'status': 'error',
            'message': 'Failed to create post'
        })
    }
}

export default createWish