import {useState, useEffect} from "react";
import Wish from "./Wish";
import Loader from "./Helpers/Loader";
import { CalendarDaysIcon, TagIcon } from "@heroicons/react/24/outline";

export default function Wishlist() {
    const [wishes, setWishes] = useState(null);

    const query = `{
        personCollection(limit: 4) {
            items {
              sys {
                id
              }
              linkedFrom{
                wishCollection {
                  items {
                    sys {
                      publishedAt
                    }
                    title, desc, url, category{title,icon{url}}
                  }
                }
              }
              name, image{url}, clothesSize
            }
          }
    }`;

    useEffect(() => {
        window.fetch(`https://graphql.contentful.com/content/v1/spaces/bbnewkew8oy0/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer 1g0Vz-lV9fMv9lh_Ee9KYQRDIHoVm9wyZUzM0Bt6ZDA"
        },
        body: JSON.stringify({query})
        })
        .then((response => response.json()))
        .then(({data, errors}) => {
        if(errors) {
            console.error(errors)
        }

        setWishes(data.personCollection.items)
        })
    }, [query])

    if(!wishes) {
        return (<Loader print="Henter ønskelister..." />)
    }

    let categories = []
    wishes.map((w) => w.linkedFrom.wishCollection.items.map((c) => categories.push(c.category.title)))
    let uniqueCategories = [...new Set(categories)]
    console.log(uniqueCategories)

    console.log(wishes)

    function lastUpdated(date) {
      const groundDate = new Date(date);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return groundDate.toLocaleDateString('no-NB', options)
    }

    return (
        <section id="wishlistcontainer">
        <h1>Ønskelister</h1>
        {wishes?.map((p, i) => (
                <article id={p.sys.id} key={p.sys.id}>
                <img className="profile" src={p?.image?.url ? p.image.url : `https://dummyimage.com/500x500&text=${p.name}`} alt={p.name} />
                <h2>{p.name}</h2>
                <ul>
                  <li className="update"><CalendarDaysIcon />Oppdatert {lastUpdated(p.linkedFrom?.wishCollection?.items[0]?.sys?.publishedAt)}</li>
                  {p.clothesSize ? <li className="clothes"><TagIcon /> Klesstørrelse: {p.clothesSize}</li> : null}
                </ul>
                <ul className="wishes">
                {p?.linkedFrom?.wishCollection?.items?.map((w, i) => (
                    <Wish  wish={w} index={i} key={i} />
                ))}
                </ul>
                </article>
            
        ))}
        </section>
    )
}