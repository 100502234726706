import {Tooltip} from "antd";

function Wish({wish, index}) {
    console.log(wish)
   return(<li key={index}>
        <span className="label">
            <Tooltip title={wish.category.title}>
                <img src={wish.category.icon.url} alt={wish.category.title} />
            </Tooltip>
        </span>
        <span>
        {wish.title}
        {wish.desc ? <p className="wishdesc">{wish.desc}</p> : null}
        </span>
        {wish.url ? <a className="button" href={wish.url}>Mer info / kjøp</a> : null}
    </li>);
}

export default Wish;