import { useEffect, useState } from "react"
import { client } from "../lib/client"
import EventCard from "./EventCard"
import Loader from "./Helpers/Loader"

export default function Calendar() {
    const [eventItems, setEventItems] = useState(null)
    const currentDate = new Date()

    async function fetchEvents() {
        await client.getEntries({
            content_type: 'event',
            order: 'fields.eventtime'
        }).then((response) => {setEventItems(response.items)})
    }

    useEffect(() => {
        fetchEvents()
    }, [])

    if(!eventItems) {
        return <Loader print="Laster inn kalenderoppføringer..." />;
    }

    return (<section id="calendar">
    <h2>Hva skjer i familien Akerbæk?</h2>
    {eventItems?.filter((f) => f.fields.eventtime >= currentDate.toISOString())
        .map((e, i) => (<EventCard e={e} key={i} />))}</section>)
}